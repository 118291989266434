<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { storeToRefs } from 'pinia';
import { useMainStore } from '~/stores/mainStore';

type IndicatorBarSize = 'small' | 'large';

const props = withDefaults(
  defineProps<{
    size?: IndicatorBarSize;
    innerShadow?: boolean;
    changeColour?: boolean;
  }>(),
  {
    size: 'small',
    innerShadow: false,
    changeColour: false,
  }
);

const { livePropertiesCount } = storeToRefs(useMainStore());

const percentage = computed<number>(() => {
  return (
    (livePropertiesCount.value.live / livePropertiesCount.value.limit) * 100
  );
});

const barColour = computed<string>(() => {
  if (!props.changeColour) return 'bg-blue-500';
  if (percentage.value >= 100) return 'bg-green-500';
  if (percentage.value >= 75) return 'bg-teal-500';
  return 'bg-blue-500';
});

const barHeight = cva('', {
  variants: {
    size: {
      small: 'h-1.5',
      large: 'h-6',
    },
  },
});

const barRounded = cva('', {
  variants: {
    size: {
      small: 'rounded-full',
      large: 'rounded-lg',
    },
  },
});
</script>

<template>
  <div
    class="w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700"
    :class="[
      barHeight({ size }),
      barRounded({ size }),
      innerShadow ? 'shadow-inner' : '',
    ]"
  >
    <div
      class="percentage"
      :class="[barColour, barHeight({ size })]"
      :style="`transform: scaleX(${percentage}%)`"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.percentage {
  transition: transform 500ms ease-out;
  transition-delay: 200ms;
  transform-origin: top left;
  transform: scaleX(0);
}
</style>
